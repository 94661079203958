<template>
  <section class="dtc-main-section mt-2 mr-2">
    <section class="search-block">
      <ProgressRecord></ProgressRecord>
      <RuralProgressmanageEdit
        @updateRuralProgress="getData"
      ></RuralProgressmanageEdit>
      <LocalProgressRemark></LocalProgressRemark>
      <h5 class="big-title">
        「原住民族及離島地區醫事人員養成計畫公費生」-偏護菁英公費生服務歷程清單
      </h5>
      <div class="dtc-search1">
        <b-input-group prepend="姓名">
          <b-input
            v-model.trim="searchName"
            placeholder="請輸入姓名"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="身分證字號">
          <b-input
            v-model.trim="searchId"
            placeholder="請輸入身分證字號"
            @keydown.enter="searchDb"
          ></b-input>
        </b-input-group>
        <b-input-group prepend="性別">
          <b-select
            :options="genderObject"
            v-model="searchGender"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="科別">
          <b-select
            :options="majorObject"
            v-model="searchMajor"
            @change="searchDb"
            disabled
          ></b-select>
        </b-input-group>

        <b-button variant="primary" @click="searchDb">進行查詢</b-button>
        <b-button variant="danger" @click="clearSearch">清除條件</b-button>
      </div>
      <div class="dtc-search">
        <b-input-group prepend="培育學校">
          <b-select
            :options="schoolObject"
            v-model="searchSchool"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="服務情形">
          <b-select
            :options="statusRuralObject"
            v-model="searchStatus"
            @change="searchDb"
          ></b-select>
        </b-input-group>
        <b-input-group prepend="服務現況">
          <b-select
            :options="serviceOption"
            v-model="searchServiceNow"
            @change="searchDb"
          ></b-select>
        </b-input-group>
      </div>
    </section>

    <header class="dtc-grid-header my-dark">
      <div
        v-for="(item, i) in headers"
        :key="`headers${i}`"
        :title="item.name || item"
        @click="sort(item)"
      >
        {{ item.name || item }}
        <span v-show="item.sortDesc === null">
          <i class="fas fa-sort"></i>
        </span>
        <span v-show="item.sortDesc === false">
          <i class="fas fa-sort-up"></i>
        </span>
        <span v-show="item.sortDesc">
          <i class="fas fa-sort-down"></i>
        </span>
      </div>
    </header>
    <main
      class="dtc-grid-header2"
      v-for="(item, i) in items"
      :key="i"
      style="color:#39312E;"
      :style="
        i % 2 == 0 ? 'background-color:#EDEDED ;' : 'background-color: #DDDDDD;'
      "
    >
      <div style="cursor: pointer;padding-top:3px;">
        <b-button
          variant="primary"
          class="mr-2"
          size="sm"
          @click="openProgressRecord(item)"
          >檢視</b-button
        >
        <b-button
          variant="warning"
          size="sm"
          @click="editProgressRecord(item)"
          class="mr-2"
          >編輯</b-button
        >
        <b-button
          variant="success"
          size="sm"
          @click="openAllProgressRemark(item)"
          >檢視完整備註</b-button
        >
      </div>
      <div :title="item.status">
        {{ item.status }}
      </div>
      <div :title="item.servicenow">
        {{ item.servicenow }}
      </div>
      <div :title="item.name">
        {{ item.name }}
      </div>
      <div :title="item.id">
        {{ item.id }}
      </div>
      <div :title="item.gender">
        {{ item.gender }}
      </div>
      <div :title="item.school">
        {{ item.school }}
      </div>
      <div :title="item.cat">
        {{ item.cat }}
      </div>
      <!-- <div :title="item.pgyTime">
        {{ item.pgyTime }}
      </div>
      <div :title="item.trainingTime">
        {{ item.trainingTime }}
      </div> -->
      <div :title="item.serviceTime">
        {{ item.serviceTime }}
      </div>

      <!-- <div :title="item.originRemark" class="note">
        {{ item.remark }}
      </div> -->
      <!-- <div :title="item.originRemark" style="padding:0;margin 0px auto">
        <b-button
          variant="success"
          size="sm"
          @click="openAllProgressRemark(item)"
          >檢視完整備註</b-button
        >
      </div> -->
    </main>
    <footer class="dtx-footer">
      <b-pagination
        v-model="currentPageNum"
        :total-rows="rowCount"
        :per-page="pagingRowPerPage"
        aria-controls="dataTable1"
        align="center"
        class="mt-2"
      >
        <span slot="first-text">
          <i class="fas fa-fast-backward"></i>
        </span>
        <span slot="prev-text">
          <i class="fas fa-step-backward"></i>
        </span>
        <span slot="next-text">
          <i class="fas fa-step-forward"></i>
        </span>
        <span slot="last-text">
          <i class="fas fa-fast-forward"></i>
        </span>
        <span slot="page" slot-scope="{ page, active }">
          <b v-if="active">{{ page }}</b>
          <i v-else>{{ page }}</i>
        </span>
      </b-pagination>
      <b-select
        :options="rows"
        class="mt-2"
        v-model="pagingRowPerPage"
      ></b-select>
      <div class="mt-3">{{ totalCountStr }}</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
import buildQuery from "odata-query";
import { store } from "@/store/global.js";
import moment from "moment";
import ProgressRecord from "@/components/systemmanage/ProgressRecord";
import RuralProgressmanageEdit from "./RuralProgressmanageEdit";
import LocalProgressRemark from "./LocalProgressRemark";
import {
  countryObject,
  genderObject,
  // schoolObject,
  // majorObject,
  countriesObject,
  statusRuralObject,
  allRuralServiceObject,
  hometownObject,
} from "@/constant.js";

const headers = [
  "操作",
  "服務情形",
  "服務現況",
  "姓名",
  "身分證字號",
  "性別",
  "培育學校",
  "科別",
  // "PGY培訓期間",
  // "專科培訓/其他醫事培訓期間",
  "服務期間",
  // "備註",
];
const zero = "T00:00:00";
const rows = [10, 20, 50];
const year = new Date().getFullYear() - 1911;
let years = new Array(9).fill().map((s, i) => year + 1 - i);
years.unshift({ value: null, text: "全部" });
// schoolObject.unshift({ value: null, text: "全部" });
// majorObject.unshift({ value: null, text: "全部" });
countryObject.unshift({ value: null, text: "全部" });

export default {
  name: "RuralProgressmanage",
  data() {
    return {
      headers,
      items: [],
      keys: [],
      currentPageNum: 1,
      rowCount: 0,
      pagingRowPerPage: 20,
      orderBy: [],
      year: null,
      search: true,
      rows,
      totalCountStr: "",
      years,
      filename: "",
      fakeData: [],
      countryObject,
      genderObject,
      schoolObject: [],
      majorObject: [],
      countriesObject,
      statusRuralObject,
      allRuralServiceObject,
      hometownObject,
      serviceOption: [],
      searchName: "",
      searchId: "",
      searchGender: null,
      searchBirthday: {
        year: "",
        month: "",
        day: "",
      },
      searchSchool: null,
      searchEntryYear: null,
      searchEndYear: null,
      searchMajor: "護理",
      searchCategory: null,
      searchHometown: null,
      searchStatus: null,
      searchServiceNow: null,
    };
  },
  components: { ProgressRecord, RuralProgressmanageEdit, LocalProgressRemark },
  computed: {},

  methods: {
    openAllProgressRemark(item) {
      store.editItem = { ...item };
      store.editItem.type = 2;
      this.$bvModal.show("allProgressRemark");
    },
    openProgressRecord(item) {
      store.studentProgressInfo = { ...item };
      this.$bvModal.show("studentProgress");
    },
    editProgressRecord(item) {
      store.editItem = { ...item };
      this.$bvModal.show("editRuralProgressRecord");
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    sort(item) {
      if (!Boolean(item.name)) {
        return;
      }
      if (item.sortDesc) {
        item.sortDesc = null;
      } else if (false === item.sortDesc) {
        item.sortDesc = true;
      } else if (null === item.sortDesc) {
        item.sortDesc = false;
      }
      this.orderBy = [];
      this.headers.forEach((s) => {
        if (!Boolean(s.name)) {
          return;
        } else if (s.sortDesc !== null) {
          this.orderBy.push(s.sortDesc ? `${s.key} desc` : `${s.key}`);
        }
      });
      this.getData();
    },
    searchDb() {
      this.search = true;
      this.getData();
    },
    async clearSearch() {
      this.searchName = "";
      this.searchId = "";
      this.searchGender = null;
      this.searchBirthday = {
        year: "",
        month: "",
        day: "",
      };
      this.searchSchool = null;
      this.searchEntryYear = null;
      this.searchEndYear = null;
      this.searchMajor = null;
      this.searchCategory = null;
      this.searchHometown = null;
      this.searchStatus = null;
      this.searchServiceNow = null;
      this.getData();
    },
    async getData() {
      //odata3 qs
      const page = this.currentPageNum;
      const skip = (page - 1) * this.pagingRowPerPage;
      const top = this.pagingRowPerPage;
      const orderBy = this.orderBy;
      const obj = { top, skip, orderBy };
      let qs = buildQuery(obj);
      qs = `${qs}&$filter=Category eq '2'`;

      if (this.searchName) {
        qs = this.$appendSubstringofFilter("Name", qs, this.searchName);
      }
      if (this.searchId) {
        qs = this.$appendSubstringofFilter("Identifier", qs, this.searchId);
      }
      if (this.searchGender) {
        qs = this.$appendSubstringofFilter("Gender", qs, this.searchGender);
      }
      if (
        Boolean(this.searchBirthday.year) &&
        Boolean(this.searchBirthday.month) &&
        Boolean(this.searchBirthday.day)
      ) {
        const date = `${this.searchBirthday.year}-${this.searchBirthday.month}-${this.searchBirthday.day}`;
        const startDate = moment(this.$usDate(date))
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        const endDate = this.$usDate(date);
        qs = this.$appendTimeFilter("BirthDate", qs, startDate, endDate);
      }
      if (this.searchSchool) {
        qs = this.$appendSubstringofFilter("SchoolCode", qs, this.searchSchool);
      }
      if (this.searchEntryYear) {
        qs = this.$appendSubstringofFilter(
          "StartYear",
          qs,
          this.searchEntryYear
        );
      }
      if (this.searchEndYear) {
        qs = this.$appendSubstringofFilter("EndYear", qs, this.searchEndYear);
      }
      if (this.searchMajor) {
        qs = this.$appendSubstringofFilter("MajorName", qs, this.searchMajor);
      }
      if (this.searchCategory) {
        qs = this.$appendSubstringofFilter("Category", qs, this.searchCategory);
      }
      if (this.searchHometown) {
        qs = this.$appendSubstringofFilter(
          "ResidenceAddrs",
          qs,
          this.searchHometown
        );
      }
      if (this.searchStatus) {
        qs = this.$appendSubstringofFilter(
          "ServiceItem",
          qs,
          this.searchStatus
        );
      }
      if (this.searchServiceNow) {
        qs = this.$appendSubstringofFilter(
          "ServiceDetail",
          qs,
          this.searchServiceNow
        );
      }

      try {
        const { Items, Count } = await window.axios.get(
          `StudentInfo/GetStudentService${qs}`
        );
        this.items = Items.map((s) => ({
          status: s.ServiceItem || "",
          servicenow: s.ServiceDetail || "",
          name: s.Name || "",
          id: s.Identifier || "",
          gender: !Boolean(s.Gender) ? "" : s.Gender == "M" ? "男" : "女",
          birthday: s.Cht_BirthDate || "",
          school: this.schoolObject.find((k) => +k.value == +s.SchoolCode)
            ? this.schoolObject.find((k) => +k.value == +s.SchoolCode).text
            : "",
          entryYear: s.StartYear || "",
          graduateYear: s.EndYear || "",
          domicile: Boolean(s.Category)
            ? this.countryObject.find((k) => +k.value == +s.Category).text
            : "",
          hometown: this.hometownObject.find((k) => k.value == +s.County)
            ? this.hometownObject.find((k) => k.value == +s.County).text
            : "",

          contactAddress: s.ContAddress || "",
          homeAddress: s.ResidenceAddrs || "",
          telphone: s.Home || "",
          mobilephone: s.Mobile || "",
          email: s.Email || "",
          remark: Boolean(s.Note) ? s.Note.replace(/\n/g, "") : "",
          originRemark: s.Note || "",
          code: s.Code || "",
          majorYear: s.MajorYear || "",
          majorName: s.MajorName || "",
          originAllData: s,
          cat: s.MajorName || "",
          type: +s.Category || "",
          pgyTime: s.PGY_Dates || "無",
          trainingTime: s.Expert_Dates || "無",
          serviceTime: s.Service_Dates || "無",
        }));

        this.rowCount = Count;
        this.totalCountStr = `共${Count} 筆`;
      } catch (e) {
        this.$bvToast.toast(`${e}`, {
          title: "系統資訊",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
    async getAllSchool() {
      const arr = await window.axios.get("School/GetAll");
      this.schoolObject = arr.map((s) => ({
        value: s.SchoolCode,
        text: s.SchoolName,
      }));
      this.schoolObject.unshift({ value: null, text: "全部" });
    },
    async getMajor() {
      const arr = await window.axios.get("SysCode/Get?category=major");
      this.majorObject = arr.map((s) => ({
        value: s.Display,
        text: s.Display,
      }));
      this.majorObject.unshift({ value: null, text: "全部" });
    },
  },
  async mounted() {
    this.getAllSchool();
    this.getMajor();
    this.getData();
  },

  watch: {
    "searchBirthday.year"(v) {
      if (v.length >= 3) {
        this.searchBirthday.year = this.searchBirthday.year.substr(0, 3);
        this.$refs.searchBirthdayMonth.focus();
      }
    },
    "searchBirthday.month"(v) {
      if (v.length >= 2) {
        this.searchBirthday.month = this.searchBirthday.month.substr(0, 2);
        this.$refs.searchBirthdayDay.focus();
      }
    },
    "searchBirthday.day"(v) {
      if (v.length > 2) {
        this.searchBirthday.day = this.searchBirthday.day.substr(1, 2);
      }
    },
    currentPageNum(v) {
      this.currentPageNum = v;
      this.getData().catch((e) => {});
    },
    pagingRowPerPage(v) {
      this.getData().catch((e) => {});
    },
    searchStatus(v) {
      if(Boolean(v)){
        this.serviceOption = this.allServiceObject[v];
        this.searchServiceNow = v=='畢業'?'畢業':null;
      }else{
        this.searchServiceNow = null;
        this.serviceOption = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dtc-main-section {
  // overflow-x: auto;
  padding: 12px;
  // position: relative;
  // height: 98vh;
}
.dtc-grid-header,
.dtc-grid-header2 {
  // width: 2230px;
  display: grid;
  grid-template-columns: 260px 150px 140px 120px 200px 120px 270px 140px 300px;
  grid-auto-flow: column;
  text-align: center;
  border-right: 0px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    /* word-break: keep-all; */
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding: 4px 0;
    border-right: 1px solid #dee2e5;
    border-bottom: 1px solid #dee2e5;
    display: -webkit-box;
    height: 36px;
  }
  > div:first-child {
    border-left: 1px solid #dee2e5;
  }
}
.dtc-grid-header {
  > div {
    line-height: 30px;
  }
}
.dtc-grid-header2 {
  > div {
    font-size: 16px;
    padding: 6px;
    padding-top: 7px;
  }
}

.my-dark {
  > div {
    // background: var(--gray);
    background: #424242;
    color: white;
  }
}
.dtc-link {
  color: var(--primary);
  cursor: pointer;
}
.dtc-search,
.dtc-search1 {
  display: grid;
  max-height: 40px;
  margin-bottom: 12px;
  grid-template-columns: 343px 310px 356px repeat(15, max-content);
  grid-gap: 12px;
  > * {
    max-height: 44px;
  }
}

.dtx-footer {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  justify-content: center;
  grid-gap: 12px;
}

.input-group-text {
  width: 110px;
  background: #0379fd;
  color: #fff;
}
.file-content {
  text-align: start;
  padding-left: 10px !important;
  .subject {
    cursor: pointer;
    font-weight: 900;
  }
  .content {
    color: #777;
  }
}
.note {
  text-align: left;
  padding-left: 5px !important;
}
.search-birthday {
  display: grid;
  grid-template-columns: 80px 12.5px 70px 12.5px 70px;
  .dash {
    margin-top: 6px;
    text-align: center;
  }
}
.search-block {
  height: 142px;
  margin-bottom: 10px;
  .big-title {
    background: #0379fd;
    padding: 5px;
    color: #fff;
    border-radius: 6px 6px 0 0;
  }
  .dtc-search,
  .dtc-search1 {
    padding-left: 10px;
  }
  background: #fbfcf9;
  border: 1px solid #bdbdbd;
  border-radius: 8px 8px 0 0;
}
</style>
